const isEmpty = (x) => ~[null, undefined, ''].indexOf(x);
const optional = (x) => isEmpty(x)
|| typeof(x) === 'object' && Object.values(x).every(isEmpty) ? undefined : x;

/** @param {Object} x */
const undefinedOptionalProperties = (x) => Object.keys(x).reduce((a, key) => ({...a, [key]: optional(x[key])}), {});

/**
 * jquery helper
 * @param {FormData} formData
 */
const serializeJQueryFormData = (formData) => formData.serializeArray && formData.serializeArray().reduce((a, x) => ({
  ...a,
  [x.name]: x.value
}), {}) || formData;

function logged_in() {
	const name = 'known_user'
	return get_cookie(name) === 'true'
}

function get_cookie(name) {
	if (!name) { return undefined }
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift() || undefined;
}

function get_device_details() {
	let userAgent = navigator.userAgent;
	let browser_name, browser_version, os_name, os_version;

	// Browser information
	if (userAgent.match(/chrome|chromium|crios/i)) {
		browser_name = 'Chrome';
		browser_version = userAgent.match(/chrome\/[\d.]+/gi).toString().replace(/chrome\//i, '');
	} else if (userAgent.match(/firefox|fxios/i)) {
		browser_name = 'Firefox';
		browser_version = userAgent.match(/firefox\/[\d.]+/gi).toString().replace(/firefox\//i, '');
	} else if (userAgent.match(/safari/i)) {
		browser_name = 'Safari';
		browser_version = userAgent.match(/version\/[\d.]+/gi).toString().replace(/version\//i, '');
	} else if (userAgent.match(/msie|trident/i)) {
		browser_name = 'Internet Explorer';
		browser_version = userAgent.match(/msie [\d.]+|rv:[\d.]+/gi).toString().replace(/msie |rv:/i, '');
	} else if (userAgent.match(/edg/i)) {
		browser_name = 'Edge';
		browser_version = userAgent.match(/edg\/[\d.]+/gi).toString().replace(/edg\//i, '');
	} else {
		browser_name = 'Unknown';
		browser_version = 'Unknown';
	}

	// Operating System information
	if (userAgent.match(/windows nt/i)) {
		os_name = 'Windows';
		os_version = userAgent.match(/windows nt [\d.]+/i).toString().replace(/windows nt /i, '');
	} else if (userAgent.match(/iphone os/i)) {
		os_name = 'iOS';
		os_version = userAgent.match(/iphone os [\d.]+/i).toString().replace(/iphone os /i, '');
	} else if (userAgent.match(/mac os x/i)) {
		os_name = 'macOS';
		os_version = userAgent.match(/mac os x [\d._]+/i).toString().replace(/mac os x /i, '');
	} else if (userAgent.match(/android/i)) {
		os_name = 'Android';
		os_version = userAgent.match(/android [\d.]+/i).toString().replace(/android /i, '');
	} else if (userAgent.match(/linux/i)) {
		os_name = 'Linux';
		os_version = 'Unknown';
	} else {
		os_name = 'Unknown';
		os_version = 'Unknown';
	}

	return {
		browser: {
			name: browser_name,
			version: browser_version
		},
		os: {
			name: os_name,
			version: os_version
		}
	};
}

// can we add the configuration constants as environment variables?
const SEEN_EVENTS_KEY = 'seen_segment_events'
const EVENT_IDENTIFIER_MAPPING = {
  "Order Completed": "order_id",
  "Checkout Completed": "order_id",
};
const register_event = (seen, name, identifier) => {
  seen[name] = identifier;
  localStorage[SEEN_EVENTS_KEY] = JSON.stringify(seen);
}
function load_seen_events() {
  return JSON.parse(localStorage[SEEN_EVENTS_KEY] || "{}");
}
function event_deduplication_middleware({ payload, integration, next }) {
  const event_type = payload.obj.type;
  if (event_type !== "track") { next(payload); return; } // if not track event, continue
  const event_name = payload.obj.name;
  const identifier_name = EVENT_IDENTIFIER_MAPPING[event_name];
  if (!identifier_name) { next(payload); return; } // if no identifier name, event has no mapped identifier
  const event_properties = payload.obj.properties;
  const identifier_value = event_properties[identifier_name];
  if (!identifier_value) { next(payload); return; } // if no identifier value collect anyways
  const seen_events = load_seen_events();
  const event_exists = seen_events[event_name] === identifier_value;
  if (event_exists) { return; } // do not call `next(payload.obj)` if event has already been seen
  register_event(seen_events, event_name, identifier_value); // register the event & its identifier
  next(payload); // collect event since event has not been seen previously
}

const load = () => {
	'use strict';


	!function(){let analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){let e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(let t=0;t<analytics.methods.length;t++){let e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){let n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";let a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
      // load Segment library

			// middlewares

			// User Traits
			analytics.addSourceMiddleware(function({ payload, integration, next }) {
				payload.obj.context.traits = window.analytics.user().traits();
				next(payload);
			});

			// add log in status
			analytics.addSourceMiddleware(function({ payload, integration, next }) {
				const active = logged_in();
				payload.obj.context.active = active;
				next(payload);
			});

			// add device details
			analytics.addSourceMiddleware(function({ payload, integration, next }) {
				const device = get_device_details();
				payload.obj.context.browser = device.browser;
				payload.obj.context.os = device.os;
				next(payload);
			});

			// add screen details
			analytics.addSourceMiddleware(function({ payload, integration, next }) {
				payload.obj.context.screen = {
					width: window.screen.availWidth,
					height: window.screen.availHeight,
					density: window.devicePixelRatio,
					depth: window.screen.colorDepth,
					orientation: window.screen.orientation.type,
					viewport: {
						width: window.innerWidth,
						height: window.innerHeight,
						visible: document.hidden === false
					}
				};
				next(payload);
			});

			analytics.addDestinationMiddleware('Google Tag Manager', function({ payload, next }) {
				payload.obj.properties.context = payload.obj.context;
				payload.obj.properties.event_id = payload.obj.messageId;
				next(payload);
			});

			// dedupe events
			analytics.registerSourceMiddleware(event_deduplication_middleware)

      analytics.load('9xOE30z6a6E9Y3gbqPYOtDez97ISDjM7');
    }}();
};

const track = (name, data) => {
	const analytics = window.analytics || [];
	analytics.track(name, undefinedOptionalProperties(data));
};

const identify = (name, data) => {
	const analytics = window.analytics || [];
	analytics.identify(name, undefinedOptionalProperties(data));
};

const dataLayer = (data) => {
	const dataLayer = window.dataLayer || [];
	dataLayer.push(data);
};

const isLoaded = () => !!window.analytics;

export default { load, track, identify, dataLayer, isLoaded, serializeJQueryFormData };
